
































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DELETE_LETTER } from '../store';
import { Letter, WriteLetter } from '../types';
import { ApiResponse } from '@/components/types';
import { MapStateLetterToWriteLetter } from '../model';
import ErrorAlert from '@/components/ErrorAlert.vue';

const Correspondence = namespace('correspondence');

@Component({
  components: { ErrorAlert }
})
export default class DeleteLetterDialog extends Vue {
  @Prop({ type: Object }) letter?: Letter;

  @Correspondence.Action(DELETE_LETTER) deleteLetter!: (letter: WriteLetter) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;
  dialog: boolean = false;
  value: WriteLetter | null = null;

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.error = null;
      this.dialog = false;
      this.value = null;
      this.$emit('update:letter', null);
    }, 200);
  }

  @Watch('letter')
  watchLetter(letter: Letter) {
    if (!letter) return;

    this.value = MapStateLetterToWriteLetter(letter);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  async save() {
    if (!this.value) return;

    this.error = null;
    this.loading = true;

    const { error } = await this.deleteLetter(this.value);

    this.loading = false;

    if (error) {
      this.error = error;
      return;
    }

    this.dialog = false;
  }
}
