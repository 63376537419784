












import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import slugify from 'slugify';
import { FETCH_LETTER_PDF } from '../store';
import { ApiResponse } from '@/components/types';

const Correspondence = namespace('correspondence');

@Component({})
export default class DownloadAction extends Vue {
  @Prop({ type: String, required: true }) uuid!: string;
  @Prop({ type: String, required: true }) receiver!: string;
  @Prop({ type: String, required: true }) description!: string;
  @Prop({ type: String, required: true }) createdDate!: string;
  @Prop({ type: String, default: 'btn-info' }) color!: string;
  @Prop({ type: Boolean, default: false }) large!: boolean;
  @Prop({ type: Boolean, default: true }) icon!: boolean;
  @Prop({ type: String }) label?: string;
  @Prop({ type: String }) href?: string;

  @Correspondence.Action(FETCH_LETTER_PDF) fetchPDF!: (letterId: string) => Promise<ApiResponse<Blob>>;

  loading: boolean = false;
  error: any = null;

  get filename(): string {
    return `${slugify(this.description)}-${slugify(this.receiver)}-${this.createdDate.split(' ')[0]}.pdf`;
  }

  async download() {
    this.error = null;
    this.loading = true;

    const { error, content } = await this.fetchPDF(this.uuid);

    if (error) {
      this.loading = false;
      this.error = error;
      return;
    }

    this.loading = false;

    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.setAttribute('download', this.filename);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
