

















































import { Component, Prop, Vue } from 'vue-property-decorator';
import ConservatorshipSelect from '@/modules/conservatorship/components/ConservatorshipSelect.vue';
import ReceiverTypeSelect from './ReceiverTypeSelect.vue';
import { LetterBuildValues } from '@/modules/correspondence/types';

@Component({
  components: { ReceiverTypeSelect, ConservatorshipSelect }
})
export default class LetterDialog extends Vue {
  @Prop({ type: Object, required: true }) value!: LetterBuildValues;

  get form(): LetterBuildValues {
    return this.value;
  }

  set form(value: LetterBuildValues) {
    this.$emit('input', value);
  }
}
