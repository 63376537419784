




























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { LetterBuildValues } from '../types';
import ConservatorshipSelect from '@/modules/conservatorship/components/ConservatorshipSelect.vue';
import LetterTemplate from './LetterTemplateTable.vue';
import LetterForm from './LetterForm.vue';

@Component({
  components: { ConservatorshipSelect, LetterForm, LetterTemplate }
})

export default class LetterBuildDialog extends Vue {
  loading: boolean = false;
  search: string = '';
  letterComponent: string = 'letter-form';
  dialog: boolean = false;
  value: LetterBuildValues = this.initValue();

  async next() {
    // @ts-ignore
    const result = await this.$refs.form.validate();

    if (!result) return;

    this.letterComponent = 'letter-template';
  }

  @Watch('dialog')
  onOpen(open: boolean) {
    if (!open) return;

    this.search = '';
    this.letterComponent = 'letter-form';
    this.value = this.initValue();
  }

  initValue(): LetterBuildValues {
    return {
      template: '',
      receiver_type: 'all',
      conservatorship: '',
      contact: {
        contact_person: '',
        name: '',
        street: '',
        city: '',
        zip_code: ''
      }
    };
  }
}
