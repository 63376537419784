
























































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_LETTER_PREVIEW, FETCH_LETTER_WORD, UPDATE_LETTER } from '../store';
import { Letter, WriteLetter } from '../types';
import { ApiResponse } from '@/components/types';
import RichEditor from './RichEditor.vue';
import ReceiverTypeSelect from './ReceiverTypeSelect.vue';
import VisibilitySelect from './VisibilitySelect.vue';
import { MapStateLetterToWriteLetter } from '../model';
import slugify from 'slugify';
import ViewDialog from '@/modules/correspondence/components/ViewDialog.vue';
import DatePicker from '@/components/form/DatePicker.vue';

const Correspondence = namespace('correspondence');

@Component({
  components: {
    DatePicker,
    ViewDialog,
    RichEditor,
    VisibilitySelect,
    ReceiverTypeSelect
  }
})
export default class UpdateLetterDialog extends Vue {
  @Prop({ type: [Object, Boolean] }) letter!: Letter;

  @Correspondence.Action(UPDATE_LETTER) updateLetter!: (letter: WriteLetter) => Promise<ApiResponse>;
  @Correspondence.Action(FETCH_LETTER_PREVIEW) fetchPreview!: (letter: WriteLetter) => Promise<ApiResponse>;
  @Correspondence.Action(FETCH_LETTER_WORD) fetchWord!: (letter: WriteLetter) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;
  loadingPreview: boolean = false;
  loadingWord: boolean = false;
  dialog: boolean = false;
  value: WriteLetter | null = null;
  view: string | null = null;
  wordUrl: string | null = null;

  get filename(): string {
    if (!this.value) return 'letter';

    const today = (new Date()).toISOString().split('T')[0];

    return slugify(`${this.letter.receiver.name}-${today}`);
  }

  cancel(observer?: any) {
    this.dialog = false;

    setTimeout(() => {
      this.error = null;
      this.dialog = false;
      this.value = null;
      this.$emit('update:letter', null);
      observer && observer.reset();
    }, 200);
  }

  @Watch('letter')
  watchLetter(letter: Letter) {
    if (!letter) return;

    this.value = MapStateLetterToWriteLetter(letter);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  async save() {
    this.error = null;

    if (!this.value) return;

    return this.updateLetter(this.value);
  }

  async preview() {
    if (!this.value) return;

    this.loadingPreview = true;

    const { error, content } = await this.fetchPreview(this.value);

    if (error) {
      this.error = error;
      this.loadingPreview = false;

      return;
    }

    this.view = content;
    this.loadingPreview = false;
  }

  async wordDocument() {
    if (this.wordUrl) {
      URL.revokeObjectURL(this.wordUrl);
    }

    if (!this.value) return;

    this.loadingWord = true;
    this.wordUrl = null;

    const { error, content } = await this.fetchWord(this.value as WriteLetter);

    if (error) {
      this.error = error;
      this.loadingWord = false;

      return;
    }

    this.wordUrl = URL.createObjectURL(content);
    this.loadingWord = false;
  }
}
