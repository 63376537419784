

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ConservatorshipSelect from '@/modules/conservatorship/components/ConservatorshipSelect.vue';
import ReceiverTypeSelect from './ReceiverTypeSelect.vue';
import { LetterBuildValues, LetterTemplate } from '../types';
import AppTable from '@/components/AppTable.vue';

@Component({
  components: { AppTable, ReceiverTypeSelect, ConservatorshipSelect }
})
export default class LetterDialog extends Vue {
  @Prop({ type: Object, required: true }) value!: LetterBuildValues;
  @Prop({ type: String }) search?: string;

  templates: LetterTemplate[] = [];
  loading: boolean = false;

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.name'), value: 'name' },
      { align: 'left', text: this.$i18n.t('common.visibility'), value: 'visibility' },
      { align: 'left', text: this.$i18n.t('label.placeholder.conservatorship'), value: null, sortable: false },
      { align: 'left', text: this.$i18n.t('label.placeholder.bankAccount'), value: null, sortable: false },
      { align: 'left', text: this.$i18n.t('common.creator'), value: 'creator' },
      { align: 'left', text: this.$i18n.t('common.actions'), value: null, sortable: false }
    ];
  }

  get form(): LetterBuildValues {
    return this.value;
  }

  set form(value: LetterBuildValues) {
    this.$emit('input', value);
  }

  create(template: string) {
    this.$router.push({
      name: 'correspondence-letter-create',
      params: { template, receiverType: this.value.receiver_type },
      query: { contact: this.value.contact as string, conservatorship: this.value.conservatorship || '' }
    }).catch(() => {
    });
  }

  @Watch('value.conservatorship', { immediate: true })
  watchConservatorship() {
    return this.fetch();
  }

  async fetch() {
    const receiverType = this.value.receiver_type;
    const requiresConservatorship = !!this.value.conservatorship;
    const requiresBankAccount = !!this.value.finance_account;

    this.loading = true;

    try {
      const response = await this.$http.get(
        `api/letter-template/list-by-values`,
        { params: { receiverType, contact: null, requiresConservatorship, requiresBankAccount } }
      );

      this.templates = response.data;
    } catch (error) {
      console.warn(error);
    }

    this.loading = false;
  }
}
