

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Letter } from '../types';

@Component({})
export default class LetterPreviewDialog extends Vue {
  @Prop({ type: Object }) letter?: Letter;

  dialog: boolean = false;

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    setTimeout(() => {
      this.$emit('update:letter', null);
    }, 200);
  }

  @Watch('letter')
  watchLetter(letter: Letter) {
    if (!letter) return;

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

}
